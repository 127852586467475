import React, { createContext, useState } from 'react'

const Context = createContext({});

export { Context }

export default function LeaveContext({ children }) {
  const [state, setState] = useState({
    stepOneComplete: false,
    stepTwoComplete: false,
    stepThreeComplete: false,
    stepFourComplete: false,
    stepFiveComplete: false,
    isCovid: false,
    covidComplete: false,
  })

  return (
    <Context.Provider value={{
      ...state,
      stepOne: (memberData) => {
        setState(prev => ({
          ...prev,
          Member: {
            ...prev.Member,
            ...memberData,
          },
          stepOneComplete: true,
          stepTwoComplete: false,
          stepThreeComplete: false,
          stepFourComplete: false,
          stepFiveComplete: false,
        }))
      },
      stepTwo: (managerData) => {
        setState(prev => ({
          ...prev, Manager: { ...prev.Manager, ...managerData }, stepTwoComplete: true, stepThreeComplete: false, stepFourComplete: false, stepFiveComplete: false,
        }))
      },
      stepThree: (contactData) => {
        setState(prev => ({
          ...prev, Contact: { ...prev.Contact, ...contactData }, stepThreeComplete: true, stepFourComplete: false, stepFiveComplete: false,
        }))
      },
      stepFour: (leaveRequestData) => {
        setState(prev => ({
          ...prev, LeaveRequest: { ...prev.LeaveRequest, ...leaveRequestData }, stepFourComplete: true, stepFiveComplete: false,
        }))
      },
      stepFive: (ID, URL) => {
        setState(() => {
          if (!ID || !URL) {
            return {
              stepOneComplete: false, stepTwoComplete: false, stepThreeComplete: false, stepFourComplete: false, stepFiveComplete: false, covidComplete: false,
            }
          }
          return {
            ID, URL, stepOneComplete: false, stepTwoComplete: false, stepThreeComplete: false, stepFourComplete: false, stepFiveComplete: true, covidComplete: false,
          }
        })
      },
      redirectCovid: (leaveRequestData) => {
        setState(prev => ({
          ...prev,
          LeaveRequest: { ...prev.LeaveRequest, ...leaveRequestData },
          isCovid: true,
          stepOneComplete: false,
          stepTwoComplete: false,
          stepThreeComplete: false,
          stepFourComplete: false,
          stepFiveComplete: false,
          covidComplete: false,
        }))
      },
      setIsCovid: isCovid => setState(prev => ({ ...prev, isCovid })),
      isCovidLeaveForEmployee: isEmployee => setState(prev => ({ ...prev, Covid: { ...prev.Covid, isEmployee } })),
      clearCovidInfo: () => setState(prev => ({
        ...prev,
        covidComplete: false,
        Covid: {
          employeeFullyVaccinated: null,
          employeeHasSymptoms: null,
          symptomStartDate: null,
          employeeCovidTestResultID: null,
          employeeLastDayOfWork: null,
          employeeTestedCovid: null,
          familyTestPositive: null,
          familyQuarantine: null,
          familyIsolate: null,
          employeeRemainHome: null,
        },
      })),
      finishCovid: covid => setState(prev => ({
        ...prev,
        covidComplete: true,
        Covid: {
          employeeFullyVaccinated: null,
          employeeHasSymptoms: null,
          symptomStartDate: null,
          employeeCovidTestResultID: null,
          employeeLastDayOfWork: null,
          employeeTestedCovid: null,
          familyTestPositive: null,
          familyQuarantine: null,
          familyIsolate: null,
          employeeRemainHome: null,
          ...prev.Covid,
          ...covid,
        },
      })),
      fetchData: data => setState(prev => ({ ...prev, ...data })),
      fetchLeaveRequests: (OpenLeaveRequests) => { setState(prev => ({ ...prev, OpenLeaveRequests })) },
      ErrorMessage: (error) => {
        setState((prev) => {
          let ErrorMessage = error
          if (!ErrorMessage) ErrorMessage = 'Error in submitting your request.'
          return { ...prev, ErrorMessage }
        })
      },
      LogOut: () => {
        setState({
          stepOneComplete: false, stepTwoComplete: false, stepThreeComplete: false, stepFourComplete: false, stepFiveComplete: false, covidComplete: false,
        })
      },
    }}
    >
      {children}
    </Context.Provider>
  )
}
